/* Crie um arquivo CSS personalizado, por exemplo, customCalendar.css */
.react-calendar {
  border: none;
  font-family: Arial, Helvetica, sans-serif;
}

.react-calendar__tile {
  background-color: transparent;
  border-radius: 2rem;
  padding: 15px;
  text-align: center;
}

.react-calendar__tile--active {
  background-color: transparent;
  color: #000000;
}

.react-calendar__tile--now {
  background-color: transparent;
  color: #13aad7;
}

.react-calendar__tile:disabled {
  background-color: transparent;
  color: #ababab;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #13aad7;
  color: #ffffff;
}

/* style.css */

/* Desabilita o botão "next2" que avança para dois meses à frente */
.react-calendar__navigation__next2-button {
    display: none;
  }
  
  /* Se desejar desabilitar o botão "prev2" (volta dois meses), pode usar */
  .react-calendar__navigation__prev2-button {
    display: none;
  }
  

  .rs-calendar-table-cell rs-calendar-table-cell-selected{
    outline: none !important;
    box-shadow: none !important;
    border: none;
  }