/*
 * Dark Colors
 * -------------------------------------------
 */

@media print {
  ion-header,
  ion-backdrop,
  .tabbar,
  ion-footer,
  ion-content::part(background) {
    display: block !important;
    overflow: hidden;
    overflow-y: hidden;
  }

  ion-nav {
    contain: none;
    overflow: visible;
  }

  ion-split-pane {
    display: block;
  }

  .scroll-content,
  ion-modal,
  ion-modal .modal-wrapper,
  ion-modal .ion-page,
  ion-modal .ion-page > ion-content,
  .ion-page,
  .ion-page > ion-content,
  ion-split-pane,
  ion-tab,
  ion-tabs,
  ion-router-outlet,
  .app-root,
  ion-content::part(scroll),
  body {
    contain: none;
    display: block !important;
    position: relative !important;
    height: auto;
    overflow: hidden;
    margin: 0;
    box-shadow: none;
  }

  .fixed-content,
  .scroll-content {
    margin-top: 0 !important;
  }
}

body.dark {
  --ion-color-primary: #428cff;
  --ion-color-primary-rgb: 66, 140, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3a7be0;
  --ion-color-primary-tint: #5598ff;
  --ion-color-secondary: #50c8ff;
  --ion-color-secondary-rgb: 80, 200, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #46b0e0;
  --ion-color-secondary-tint: #62ceff;
  --ion-color-tertiary: #6a64ff;
  --ion-color-tertiary-rgb: 106, 100, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #5d58e0;
  --ion-color-tertiary-tint: #7974ff;
  --ion-color-success: #2fdf75;
  --ion-color-success-rgb: 47, 223, 117;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #29c467;
  --ion-color-success-tint: #44e283;
  --ion-color-warning: #ffd534;
  --ion-color-warning-rgb: 255, 213, 52;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0bb2e;
  --ion-color-warning-tint: #ffd948;
  --ion-color-danger: #ff4961;
  --ion-color-danger-rgb: 255, 73, 97;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #e04055;
  --ion-color-danger-tint: #ff5b71;
  --ion-color-dark: #f4f5f8;
  --ion-color-dark-rgb: 244, 245, 248;
  --ion-color-dark-contrast: #000000;
  --ion-color-dark-contrast-rgb: 0, 0, 0;
  --ion-color-dark-shade: #d7d8da;
  --ion-color-dark-tint: #f5f6f9;
  --ion-color-medium: #989aa2;
  --ion-color-medium-rgb: 152, 154, 162;
  --ion-color-medium-contrast: #000000;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #86888f;
  --ion-color-medium-tint: #a2a4ab;
  --ion-color-light: #222428;
  --ion-color-light-rgb: 34, 36, 40;
  --ion-color-light-contrast: #ffffff;
  --ion-color-light-contrast-rgb: 255, 255, 255;
  --ion-color-light-shade: #1e2023;
  --ion-color-light-tint: #383a3e;
}

/*
  * iOS Dark Theme
  * -------------------------------------------
  */

.ios body.dark {
  --ion-background-color: #000000;
  --ion-background-color-rgb: 0, 0, 0;
  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;
  --ion-color-step-50: #0d0d0d;
  --ion-color-step-100: #1a1a1a;
  --ion-color-step-150: #262626;
  --ion-color-step-200: #333333;
  --ion-color-step-250: #404040;
  --ion-color-step-300: #4d4d4d;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;
  --ion-item-background: #000000;
  --ion-card-background: #1c1c1d;
}

.ios body.dark ion-modal {
  --ion-background-color: var(--ion-color-step-100);
  --ion-toolbar-background: var(--ion-color-step-150);
  --ion-toolbar-border-color: var(--ion-color-step-250);
  --ion-item-background: var(--ion-color-step-150);
}

/*
  * Material Design Dark Theme
  * -------------------------------------------
  */

.md body.dark {
  --ion-background-color: #121212;
  --ion-background-color-rgb: 18, 18, 18;
  --ion-text-color: #ffffff;
  --ion-text-color-rgb: 255, 255, 255;
  --ion-border-color: #222222;
  --ion-color-step-50: #1e1e1e;
  --ion-color-step-100: #2a2a2a;
  --ion-color-step-150: #363636;
  --ion-color-step-200: #414141;
  --ion-color-step-250: #4d4d4d;
  --ion-color-step-300: #595959;
  --ion-color-step-350: #656565;
  --ion-color-step-400: #717171;
  --ion-color-step-450: #7d7d7d;
  --ion-color-step-500: #898989;
  --ion-color-step-550: #949494;
  --ion-color-step-600: #a0a0a0;
  --ion-color-step-650: #acacac;
  --ion-color-step-700: #b8b8b8;
  --ion-color-step-750: #c4c4c4;
  --ion-color-step-800: #d0d0d0;
  --ion-color-step-850: #dbdbdb;
  --ion-color-step-900: #e7e7e7;
  --ion-color-step-950: #f3f3f3;
  --ion-item-background: #1e1e1e;
  --ion-toolbar-background: #1f1f1f;
  --ion-tab-bar-background: #1f1f1f;
  --ion-card-background: #1e1e1e;
}

:root {
  --ion-color-primary: #3880ff;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;
  --ion-color-secondary: #70baf9;
  --ion-color-secondary-rgb: 112, 186, 249;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #63a4db;
  --ion-color-secondary-tint: #7ec1fa;
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 0, 0, 0;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 0, 0, 0;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
  --ion-color-success-light: #16adad;
  --ion-color-success-light-rgb: 22, 173, 173;
  --ion-color-success-light-contrast: #ffffff;
  --ion-color-success-light-contrast-rgb: 255, 255, 255;
  --ion-color-success-light-shade: #139898;
  --ion-color-success-light-tint: #2db5b5;
  --ion-color-warning-light: #f5c92c;
  --ion-color-warning-light-rgb: 245, 201, 44;
  --ion-color-warning-light-contrast: #000000;
  --ion-color-warning-light-contrast-rgb: 0, 0, 0;
  --ion-color-warning-light-shade: #d8b127;
  --ion-color-warning-light-tint: #f6ce41;
  --ion-color-secondary-light: #13aad7;
  --ion-color-secondary-light-rgb: 19, 170, 215;
  --ion-color-secondary-light-contrast: #fff;
  --ion-color-secondary-light-contrast-rgb: rgba(255, 255, 255, 1);
  --ion-color-secondary-light-shade: #1196bd;
  --ion-color-secondary-light-tint: #2bb3db;
  --ion-color-light-light: #d4d7db;
  --ion-color-light-light-rgb: 212, 215, 219;
  --ion-color-light-light-contrast: #000000;
  --ion-color-light-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-light-shade: #bbbdc1;
  --ion-color-light-light-tint: #d8dbdf;
  --ion-color-white: #ffffff;
  --ion-color-white-rgb: 255, 255, 255;
  --ion-color-white-contrast: #000000;
  --ion-color-white-contrast-rgb: 0, 0, 0;
  --ion-color-white-shade: #e0e0e0;
  --ion-color-white-tint: #ffffff;
}

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}

.ion-color-light-light {
  --ion-color-base: var(--ion-color-light-light);
  --ion-color-base-rgb: var(--ion-color-light-light-rgb);
  --ion-color-contrast: var(--ion-color-light-light-contrast);
  --ion-color-contrast-rgb: var(--ion-color-light-light-contrast-rgb);
  --ion-color-shade: var(--ion-color-light-light-shade);
  --ion-color-tint: var(--ion-color-light-light-tint);
}

.ion-color-secondary-light {
  --ion-color-base: var(--ion-color-secondary-light);
  --ion-color-base-rgb: var(--ion-color-secondary-light-rgb);
  --ion-color-contrast: var(--ion-color-secondary-light-contrast);
  --ion-color-contrast-rgb: var(--ion-color-secondary-light-contrast-rgb);
  --ion-color-shade: var(--ion-color-secondary-light-shade);
  --ion-color-tint: var(--ion-color-secondary-light-tint);
}

.ion-color-warning-light {
  --ion-color-base: var(--ion-color-warning-light);
  --ion-color-base-rgb: var(--ion-color-warning-light-rgb);
  --ion-color-contrast: var(--ion-color-warning-light-contrast);
  --ion-color-contrast-rgb: var(--ion-color-warning-light-contrast-rgb);
  --ion-color-shade: var(--ion-color-warning-light-shade);
  --ion-color-tint: var(--ion-color-warning-light-tint);
}

.ion-color-success-light {
  --ion-color-base: var(--ion-color-success-light);
  --ion-color-base-rgb: var(--ion-color-success-light-rgb);
  --ion-color-contrast: var(--ion-color-success-light-contrast);
  --ion-color-contrast-rgb: var(--ion-color-success-light-contrast-rgb);
  --ion-color-shade: var(--ion-color-success-light-shade);
  --ion-color-tint: var(--ion-color-success-light-tint);
}

.borderButtonColorWhite {
  border: #d4d7db 2px solid;
  width: 45px;
  height: 45px;
}
ion-item {
  --transition: none;
}

table {
  border-collapse: collapse;
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.table tbody + tbody {
  border-top: 2px solid #dee2e6;
}

.table {
  border-collapse: collapse !important;
}

icon*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}

[tabindex="-1"]:focus:not(:focus-visible) {
  outline: 0 !important;
}

body {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 600;
  color: #677483;
  background-color: #ffffff;
  margin: 0;
  font-size: 17px;
  transition: transform 750ms;
  will-change: transform;
}

@keyframes fadein {
  from {
    transform: translateY(-100%);
    transform: translateX(10px);
  }

  to {
    transform: translateY(0);
    transform: translateX(0);
  }
}

.animacaoCarregamento {
  animation: fadein 600ms;
}

.card {
}

.input {
  height: 43px;
  border-radius: 11px;
  outline: none;
  width: 100%;
  font-weight: 500;
  text-align: justify;
  padding: 10px;
  font-size: 15px;
  margin-top: 1%;
  margin-bottom: 1%;
  border: solid 2px #ececec;
  transition: transform 750ms;
  will-change: transform;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
}

.input:hover {
  transition: transform 750ms;
  border: solid 2px #bababa;
  will-change: transform;
  transform: translateY(-1px);
  opacity: 0.8;
}

.input:focus {
  border: solid 2px #999999;
  transition: transform 750ms;
  transform: translateY(-1px);
  will-change: transform;
}

.inputTime {
  height: 43px;
  border-radius: 11px;
  outline: none;
  width: 100%;
  font-weight: 500;
  text-align: justify;
  padding: 10px;
  font-size: 15px;
  margin-top: 1%;
  margin-bottom: 1%;
  border: none;
  border: solid 2px #9ba6b6;
  transition: transform 750ms;
  will-change: transform;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
  background: #fff;
  color: #000;
}

.inputTime:hover {
  transition: transform 750ms;
  border: solid 2px #1e90f4;
  will-change: transform;
  transform: translateY(-1px);
  opacity: 0.8;
}

.borderRadius1Rem {
  border-radius: 10px;
}

.textoMini {
  font-size: 10px;
}

select,
option {
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
}

::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar-thumb {
  display: none;
}

::-webkit-scrollbar-thumb:hover {
  display: none;
}

a:link {
  color: #141414;
  text-decoration: none !important;
}

a:visited {
  color: #141414;
  text-decoration: none !important;
}

a:hover {
  color: #141414;
  text-decoration: none !important;
}

a:active {
  color: #141414;
  text-decoration: none !important;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0px;
}

.centro {
  text-align: center;
}

.centroHV {
  display: grid;
  justify-content: center;
  align-items: center;
}

.img-fluid {
  width: 100%;
  height: auto;
  box-shadow: 9px 9px 18px #dfe6e6, -9px -9px 18px #ffffff;
  border-radius: 2rem;
}

.banner {
  width: 100%;
  height: auto;
}

.esquerda {
  text-align: left;
}

.direita {
  text-align: right;
}

h1 {
  font-weight: 900;
}

h2 {
  font-weight: 900;
}

h3 {
  font-weight: 900;
}

h4 {
  font-weight: 900;
}

h5 {
  font-weight: 900;
}

h6 {
  font-weight: 900;
}

p {
  font-weight: 500;
}

.ocultar {
  display: none;
}

.botao40 {
  height: 40px;
  width: 40px;
}

ion-slides {
  height: 100%;
}

ion-button {
  font-style: normal;
  font-weight: 500;
  --box-shadow: none;
  height: 45px;
  border-radius: 12px;
  text-transform: capitalize;
  transition: transform 750ms;
  will-change: transform;
}

ion-button:hover {
  transition: transform 750ms;
  will-change: transform;
  transform: translateY(-1px);
  opacity: 0.9;
}

ion-card {
  border: none;
  box-shadow: 0 6px 24px rgba(0, 0, 0, 0.1);
  border-radius: 14px;
  overflow: hidden;
}

ion-item {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 700;
}

ion-modal {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 700;
  color: #677483;
  border-radius: 12px;
}

ion-header {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 700;
  color: #677483;
  /*margin-top: 3vh; */
}

ion-title {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 700;
  color: #677483;
}

ion-subtitle {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 700;
  color: #677483;
}

ion-label {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 700;
  color: #677483;
}

ion-app {
  font-style: normal;
  font-weight: 500;
  color: #677483;
}

ion-action-sheet {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  overflow: hidden;
}

ion-tab-bar {
  padding-top: 1%;
}

.ion-activatable {
  position: relative;
  overflow: hidden;
}

ion-slides {
  height: 100%;
}

ion-menu {
  color: #677483;
}

ion-fab-button {
  font-style: normal;
  font-weight: 700;
  --box-shadow: none;
}

ion-chip {
  border-radius: 12px;
}

.app {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: -100vw;
  overflow-y: scroll;
  z-index: 0;
  transition: transform 750ms;
  will-change: transform;
  transform: translateY(-100%);
  transform: translateX(-10%);
}

.app:target {
  left: 0vw;
  z-index: 1;
  transform: translateY(0);
  transform: translateX(0px);
}

.slide {
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: -100vw;
  overflow-y: scroll;
  z-index: 0;
  transition: transform 750ms;
  will-change: transform;
  transform: translateY(-1%);
  transform: translateX(-1%);
}

.slide:target {
  left: 0vw;
  z-index: 1;
  transform: translateY(0);
  transform: translateX(0px);
}

.footerBotaoSlide {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  background-color: #000000;
}

.alturaDoBotao50px {
  height: 50px;
}

.containerCentro {
  width: 100vw;
  height: 100vh;
  display: flex;
}

.containerCentroItem {
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.MenuAdmin {
  position: fixed;
  right: 0;
  top: 0;
  left: 0;
  z-index: 1030;
  border: none;
  color: #0095da;
  background: transparent;
}

.MenuAplicativo {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
  border: none;
}

.navigation {
  position: absolute;
  width: 100%;
  height: 60px;
  background: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.navigation ul {
  display: flex;
  width: 280px;
  text-transform: capitalize;
}

.navigation ul li {
  position: relative;
  list-style: none;
  width: 70px;
  height: 60px;
  z-index: 2;
}

.navigation ul li a {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  text-align: center;
}

.navigation ul li a .icon {
  position: relative;
  display: block;
  line-height: 65px;
  font-size: 1.5em;
  transition: 0.5s;
  color: #ffffff;
}

.navigation ul li.active a .icon {
  transform: translateY(-30px);
  color: #fff;
}

.navigation ul li a .text {
  position: absolute;
  color: #000000;
  font-weight: 400;
  font-size: 0.75em;
  letter-spacing: 0.05em;
  transition: 0.5s;
  opacity: 0;
  transform: translateY(15px);
  background: #fff;
  padding: 2px 7px;
  border-radius: 12px;
}

.navigation ul li.active a .text {
  transform: translateY(-4px);
  opacity: 1;
}

.indicator {
  position: absolute;
  top: -35px;
  width: 70px;
  height: 70px;
  background: #000000;
  border-radius: 50%;
  z-index: 1;
  transition: 0.5s;
}

.indicator::before {
  content: "";
  position: absolute;
  top: 5px;
  left: -28px;
  width: 30px;
  height: 30px;
  background: transparent;
  border-radius: 50%;
  box-shadow: 15px 18px #000000;
}

.indicator::after {
  content: "";
  position: absolute;
  top: 5px;
  right: -28px;
  width: 30px;
  height: 30px;
  background: transparent;
  border-radius: 50%;
  box-shadow: -15px 18px #000000;
}

.navigation ul li:nth-child(1).active ~ .indicator {
  transform: translateX(calc(70px * 0));
}

.navigation ul li:nth-child(2).active ~ .indicator {
  transform: translateX(calc(70px * 1));
}

.navigation ul li:nth-child(3).active ~ .indicator {
  transform: translateX(calc(70px * 2));
}

.navigation ul li:nth-child(4).active ~ .indicator {
  transform: translateX(calc(70px * 3));
}

.star-rating {
  font-size: 0;
  white-space: nowrap;
  display: inline-block;
  /* width: 250px; remove this */
  height: 50px;
  overflow: hidden;
  position: relative;
  background: url("data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iMjBweCIgaGVpZ2h0PSIyMHB4IiB2aWV3Qm94PSIwIDAgMjAgMjAiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDIwIDIwIiB4bWw6c3BhY2U9InByZXNlcnZlIj48cG9seWdvbiBmaWxsPSIjREREREREIiBwb2ludHM9IjEwLDAgMTMuMDksNi41ODMgMjAsNy42MzkgMTUsMTIuNzY0IDE2LjE4LDIwIDEwLDE2LjU4MyAzLjgyLDIwIDUsMTIuNzY0IDAsNy42MzkgNi45MSw2LjU4MyAiLz48L3N2Zz4=");
  background-size: contain;
}

.star-rating:hover {
  transform: scale(1.1);
}

.star-rating i {
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  /* width: 20%; remove this */
  z-index: 1;
  background: url("data:image/svg+xml;base64,PHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB3aWR0aD0iMjBweCIgaGVpZ2h0PSIyMHB4IiB2aWV3Qm94PSIwIDAgMjAgMjAiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDIwIDIwIiB4bWw6c3BhY2U9InByZXNlcnZlIj48cG9seWdvbiBmaWxsPSIjRkZERjg4IiBwb2ludHM9IjEwLDAgMTMuMDksNi41ODMgMjAsNy42MzkgMTUsMTIuNzY0IDE2LjE4LDIwIDEwLDE2LjU4MyAzLjgyLDIwIDUsMTIuNzY0IDAsNy42MzkgNi45MSw2LjU4MyAiLz48L3N2Zz4=");
  background-size: contain;
}

.star-rating input {
  -moz-appearance: none;
  -webkit-appearance: none;
  opacity: 0;
  display: inline-block;
  /* width: 20%; remove this */
  height: 100%;
  margin: 0;
  padding: 0;
  z-index: 2;
  position: relative;
}

.star-rating input:hover + i,
.star-rating input:checked + i {
  opacity: 1;
}

.star-rating i ~ i {
  width: 40%;
}

.star-rating i ~ i ~ i {
  width: 60%;
}

.star-rating i ~ i ~ i ~ i {
  width: 80%;
}

.star-rating i ~ i ~ i ~ i ~ i {
  width: 100%;
}

::after,
::before {
  height: 100%;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  text-align: center;
  vertical-align: middle;
}

.star-rating.star-5 {
  width: 250px;
}

.star-rating.star-5 input,
.star-rating.star-5 i {
  width: 20%;
}

.star-rating.star-5 i ~ i {
  width: 40%;
}

.star-rating.star-5 i ~ i ~ i {
  width: 60%;
}

.star-rating.star-5 i ~ i ~ i ~ i {
  width: 80%;
}

.star-rating.star-5 i ~ i ~ i ~ i ~ i {
  width: 100%;
}

.star-rating.star-3 {
  width: 150px;
}

.star-rating.star-3 input,
.star-rating.star-3 i {
  width: 33.33%;
}

.star-rating.star-3 i ~ i {
  width: 66.66%;
}

.star-rating.star-3 i ~ i ~ i {
  width: 100%;
}

iframe {
  border-radius: 1rem;
}

.imgOnboarding {
  height: 70vh;
  width: 70vw;
  margin-top: 2vh;
  box-shadow: 0 6px 24px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.divOnboarding {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 2vh;
  margin-left: 3vw;
  margin-right: 3vw;
}

.h6TextoOnboarding {
  font-size: 12px;
  margin: 2px;
}

.divH6TextoOnboarding {
  text-align: left;
}

.divBotaoAvancar {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
  background-color: #000000;
}

#slideshow {
  margin: 2px auto;
  position: relative;
  width: 100vw;
  height: 80vh;
  padding: 10px;
}

#slideshow > div {
  left: 10px;
  right: 10px;
  bottom: 10px;
  box-shadow: 0 6px 24px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin: 5px;
}

.textoCardSlideDownload {
  font-weight: 500;
  font-size: 15px;
  padding: 4%;
  text-align: justify;
  color: #000000;
}

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12,
.col,
.col-auto,
.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm,
.col-sm-auto {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 5px;
  padding-left: 5px;
}

.col {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: none;
}

.col-1 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 8.333333%;
  flex: 0 0 8.333333%;
  max-width: 8.333333%;
}

.col-2 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 16.666667%;
  flex: 0 0 16.666667%;
  max-width: 16.666667%;
}

.col-3 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 25%;
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.333333%;
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}

.col-5 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 41.666667%;
  flex: 0 0 41.666667%;
  max-width: 41.666667%;
}

.col-6 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 50%;
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 58.333333%;
  flex: 0 0 58.333333%;
  max-width: 58.333333%;
}

.col-8 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 66.666667%;
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
}

.col-9 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 75%;
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 83.333333%;
  flex: 0 0 83.333333%;
  max-width: 83.333333%;
}

.col-11 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 91.666667%;
  flex: 0 0 91.666667%;
  max-width: 91.666667%;
}

.col-12 {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 100%;
  flex: 0 0 100%;
  max-width: 100%;
}

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .col-sm-auto {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }

  .col-sm-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-sm-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-sm-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-sm-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-sm-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-sm-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-sm-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-sm-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-sm-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.checkBox {
  display: none !important;
}

.label_item div {
  background-color: #ffffff;
  color: #000000;
  width: 100%;
  height: auto;
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  border-radius: 7px;
  box-shadow: 0 6px 24px rgb(0 0 0 / 10%);
  transition: transform 750ms;
  will-change: transform;
  animation: fadein 600ms;
  font-weight: 500;
  flex-wrap: nowrap;
  align-content: center;
  align-items: center;
}

.checkBox:checked + label div {
  background-color: #287066;
  color: #ffffff;
  width: 100%;
  height: auto;
  text-align: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  border-radius: 7px;
  box-shadow: 0 6px 24px rgba(0, 0, 0, 0.1);
  transition: transform 750ms;
  will-change: transform;
  animation: fadein 600ms;
  font-weight: 500;
}

label {
  cursor: pointer;
}

.botaoCompra {
  margin-top: 3vh;
}

.botaoCompra ion-button {
  border-radius: 10px;
}

.botao40HeightWidth {
  height: 40px;
  width: 40px;
}

.radio_item {
  display: none !important;
  animation: fadein 600ms;
}

.label_item div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  color: #000;
  width: "100%";
  min-width: 160px !important;
  border-radius: 11px;
  box-shadow: 0 5px 20px rgba(155, 140, 140, 0.1);
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: 8px;
}

@keyframes rippleRadio {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

.radio_item:checked + label div {
  background-color: #13aad7;
  color: #fff;
  transition: transform 750ms;
  will-change: transform;
  animation: rippleRadio 600ms;
}

label {
  cursor: pointer;
}

.inputTableEdit {
  height: 45px;
  outline: none;
  width: 100%;
  font-weight: 500;
  text-align: left;
  font-size: 15px;
  margin-top: 1%;
  margin-bottom: 1%;
  border: none;
  box-shadow: none;
  background-color: transparent;
  -moz-appearance: none !important;
  -webkit-appearance: none !important;
  appearance: none !important;
}

.botaoTableEdit {
  height: 36px;
  width: 36px;
}

.botaoTableEdit ion-icon {
  font-size: 18px;
}

.nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
}

.nav-link:hover,
.nav-link:focus {
  text-decoration: none;
}

.nav-link.disabled {
  color: #6c757d;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}

.nav-tabs .nav-item {
  margin-bottom: -1px;
}

.nav-tabs .nav-link {
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
}

.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}

.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  border-radius: 0.25rem;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #007bff;
}

.nav-fill .nav-item {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified .nav-item {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-align: center;
}

.tab-content > .tab-pane {
  display: none;
}

.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0.5rem 1rem;
}

.navbar > .container,
.navbar > .container-fluid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.navbar-brand {
  display: inline-block;
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  line-height: inherit;
  white-space: nowrap;
}

.navbar-brand:hover,
.navbar-brand:focus {
  text-decoration: none;
}

.navbar-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}

.navbar-nav .dropdown-menu {
  position: static;
  float: none;
}

.navbar-text {
  display: inline-block;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.navbar-toggler:hover,
.navbar-toggler:focus {
  text-decoration: none;
}

.navbar-toggler:not(:disabled):not(.disabled) {
  cursor: pointer;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}

@media (max-width: 575.98px) {
  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .navbar-expand-sm .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-sm .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }

  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-sm > .container,
  .navbar-expand-sm > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .navbar-expand-sm .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }

  .navbar-expand-sm .navbar-toggler {
    display: none;
  }

  .navbar-expand-sm .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
  }
}

@media (max-width: 767.98px) {
  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 768px) {
  .navbar-expand-md {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .navbar-expand-md .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-md .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }

  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-md > .container,
  .navbar-expand-md > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .navbar-expand-md .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }

  .navbar-expand-md .navbar-toggler {
    display: none;
  }

  .navbar-expand-md .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
  }
}

@media (max-width: 991.98px) {
  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 992px) {
  .navbar-expand-lg {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .navbar-expand-lg .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-lg .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-lg > .container,
  .navbar-expand-lg > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .navbar-expand-lg .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }

  .navbar-expand-lg .navbar-toggler {
    display: none;
  }

  .navbar-expand-lg .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
  }
}

@media (max-width: 1199.98px) {
  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (min-width: 1200px) {
  .navbar-expand-xl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }

  .navbar-expand-xl .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }

  .navbar-expand-xl .navbar-nav .dropdown-menu-right {
    right: 0;
    left: auto;
  }

  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .navbar-expand-xl > .container,
  .navbar-expand-xl > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }

  .navbar-expand-xl .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }

  .navbar-expand-xl .navbar-toggler {
    display: none;
  }

  .navbar-expand-xl .dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
  }
}

.navbar-expand {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  padding-right: 0;
  padding-left: 0;
}

.navbar-expand .navbar-nav {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}

.navbar-expand .navbar-nav .dropdown-menu-right {
  right: 0;
  left: auto;
}

.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.navbar-expand > .container,
.navbar-expand > .container-fluid {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}

.navbar-expand .navbar-collapse {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
}

.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-expand .dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-brand:hover,
.navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-nav .nav-link:hover,
.navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}

.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-link.show,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.1);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.5);
}

.navbar-light .navbar-text a {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}

.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
  color: #fff;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-nav .nav-link:hover,
.navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}

.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}

.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .active > .nav-link,
.navbar-dark .navbar-nav .nav-link.show,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}

.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.1);
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.5);
}

.navbar-dark .navbar-text a {
  color: #fff;
}

.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

.containerNavPc {
}

@media (min-width: 768px) {
  .containerNavPc {
    padding-left: 15%;
    padding-right: 15%;
  }
}

@media (max-width: 575.98px) {
  .nameLogoNavbar {
    font-size: 20px;
    margin-left: -2%;
    text-align: left;
  }

  .botaoComecar {
    height: 50px;
    width: 200px;
    background-color: #000000;
    font-size: 15px;
  }

  .itemImagemFaixaRelatorio {
    margin-top: 10%;
  }

  .containerDiv1Mobile {
    padding-left: 15px;
    padding-right: 15px;
    text-align: left;
  }

  .tituloDiv1 {
    font-size: 50px;
    margin-top: 5%;
  }
}

.nav-link {
  margin-left: 30px;
}

.containerFlex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.itemFlex {
  flex-direction: row;
}

.altura92Vh {
  height: 67vh;
}

.altura76Vh {
  height: 67vh;
  overflow-y: scroll;
}

.altura86Vh {
  height: 67vh;
}

.chatInput {
  margin-top: 0%;
}

.marginLeftRight1 {
  margin-left: 3%;
  margin-right: 3%;
}

.marginInputChat {
}

.chatInputMarginTopBottom {
  margin-top: 5px;
  margin-bottom: 5px;
}

.inputChatArea {
  height: 43px;
}

.cardChat {
  border-radius: 10px;
  font-weight: 900;
}

.marginLeftRightCard {
  margin-left: 2%;
  margin-right: 2%;
}

.ionChat2 {
  font-size: 30px;
  margin-top: 17px;
  margin-right: 20px;
  cursor: pointer;
}

.ionChatSend {
  font-size: 30px;
  margin-top: 17px;
  margin-left: 20px;
  cursor: pointer;
}

.ionChatInicial {
  font-size: 30px;
  margin-top: 17px;
  margin-left: 28px;
  margin-right: 20px;
  cursor: pointer;
}

.flexInputChat {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.iconMenu {
  font-size: 20px;
}

.centroIconeMenu {
  display: flex;
  justify-content: center;
  align-items: center;
}

.textoMenu {
  font-size: 17px;
  margin-top: 0;
  cursor: pointer;
}

.iconeMenu {
  font-size: 17px;
  cursor: pointer;
}

.margemMenu {
  margin-bottom: -1%;
}

.iconeLogoMenu {
  font-size: 38px;
}

.containerLogoMenu {
  margin-left: -20%;
  margin-right: 20%;
}

.inputFile::-webkit-file-upload-button {
  visibility: hidden;
}

.inputFile::after {
  visibility: hidden;
}

.inputFile::before {
  content: "Selecionar";
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #5a5ee7;
  border-radius: 2rem;
  padding: 5px;
  outline: none;
  width: 100%;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  font-weight: 700;
  height: 50px;
  color: #ffffff;
  font-size: 17px;
}

.inputFile:hover::before {
  opacity: 0.9;
}

.inputFile:active::before {
  opacity: 0.9;
}

.cardChatEmpresa {
  border-top-right-radius: 0;
  border-top-left-radius: 16px;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
}

.cardChatCliente {
  border-top-right-radius: 16px;
  border-top-left-radius: 0;
  border-bottom-right-radius: 16px;
  border-bottom-left-radius: 16px;
}

.ativarCursor {
  cursor: pointer;
}

.iconeVoltarForm {
  font-size: 27px;
  cursor: pointer;
}

.divIconeVoltarForm {
  margin-top: 4%;
}

.divTituloForm {
  margin-top: -9%;
}

.marginLeftRight13px {
  margin-left: 13px;
  margin-right: 13px;
}

.tituloPagina {
  font-size: 38px;
  margin-left: 5px;
}

.subTituloPagina {
  margin-left: 5px;
}

.minWith100 {
  min-width: 100%;
}

.itemMenuPc {
  margin: 0;
  font-weight: 400;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: transform 750ms;
  will-change: transform;
  height: 45px;
  font-size: 15px;
}

.itemMenuPc:hover {
  transition: transform 750ms;
  will-change: transform;
  background-color: #13aad7;
  color: #fff;
  border-radius: 14px;
  height: 45px;
  animation: animacaoMenuItem 600ms;
}

.itemMenuPcAtivar {
  transition: transform 750ms;
  will-change: transform;
  background-color: #13aad7;
  color: #fff;
  border-radius: 14px;
  height: 45px;
  animation: animacaoMenuItem 600ms;
}

.inputFileContainer {
  border: solid 2px #ececec;
  border-radius: 11px;
  margin-top: 5px;
  margin-bottom: 5px;
}

input[type="file"]::file-selector-button {
  background-color: #f6f8fc;
  color: #333333;
  border: 0px;
  border-right: 1px solid #e5e5e5;
  padding: 10px 15px;
  margin-right: 20px;
  transition: 0.5s;
  box-shadow: 0 6px 24px rgba(0, 0, 0, 0.1);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

input[type="file"]::file-selector-button:hover {
  background-color: #f6f8fc;
  color: #333333;
  border: 0px;
  box-shadow: 0 6px 24px rgba(0, 0, 0, 0.1);
  border-right: 1px solid #e5e5e5;
}

.itemMenuMobile {
  border-bottom: solid 0.1px #e8e7ec;
  font-weight: 500;
  display: flex;
  justify-content: flex-start;
  transition: transform 2s;
  will-change: transform;
  font-size: 20px;
  cursor: pointer;
}

.itemMenuMobile:active {
  opacity: 0.8;
}

.itemMenuMobile:hover {
  opacity: 0.9;
}

.textoItemMenuMobile {
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #000000;
  padding: 12px;
  font-size: 19px;
}

.textoItemMenuMobile:hover {
  color: #333333;
}

@keyframes animacaoMenuItem {
  from {
    transform: translateY(-100%);
    transform: translateX(10px);
  }

  to {
    transform: translateY(0);
    transform: translateX(0);
  }
}

.iconeMenuPc {
  display: none;
}

.iconeMenuPc:hover {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: 115px;
}

@media (max-width: 1027px) {
  .menuPc {
    display: none;
  }
}

@media (max-width: 1280px) {
  .logoNavBar {
    height: 30px;
    width: 30px;
  }

  .nameLogoNavbar {
    font-size: 15px;
  }

  .itemMenuPc {
    font-size: 12px;
  }
}

.icone19px {
  font-size: 18px;
}

.containerFaixaRelatorio {
  background-color: #16adad;
  color: #ffffff;
  padding: 10px;
  font-size: 25px;
  text-align: left;
  display: flex;
  border-radius: 11px;
}

.itemImagemFaixaRelatorio {
  flex-direction: column;
  margin-top: 1%;
}

.ImagemFaixaRelatorio {
  height: 62px;
  width: 60px;
  top: 5%;
  border-radius: 11px;
}

.itemFaixaRelatorio {
  flex-direction: column;
  margin-top: 1%;
  text-transform: uppercase;
}

.descricaoItemRelatorio {
  line-height: 10px;
}

.botaoSlide {
  height: 60px;
  width: 60px;
  border-radius: 50%;
}

.inputCodigoVerificacao {
  height: 75px;
  font-size: 30px;
  font-weight: bolder;
  text-align: center;
}

.bannerContainer {
  margin-left: 2px;
  margin-right: 2px;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
}

.bannerItem {
  flex: 0 0 auto;
  width: 300px;
  margin: 0 10px;
  text-align: center;
}

.imgBannerItem {
  width: 100%;
  height: auto;
  border-radius: 11px;
}

.containerRowFlex {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
}

/* Estilos e animaÃ§Ãµes para o componente card-resumo */
.animated-card {
  animation: fade-in 1s ease-out;
}

.slide-up {
  animation: slide-in-up 1s ease-in;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes slide-in-up {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}

/* Estilos e animaÃ§Ãµes para o componente card-resumo-lite */
.animated-chip {
  animation: fade-in 1s ease-out;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.rowSistema {
  display: flex;
  flex-direction: row;
}

#colunaMenu {
  flex: 20%;
}

#colunaSistema {
  flex: 80%;
}

@media (max-width: 768px) {
  .rowSistema {
    flex-direction: column;
  }

  #colunaMenu {
    display: none;
  }

  #colunaSistema {
    flex: none;
    width: 100%;
    margin: 0%;
    padding: 0%;
  }
}

.rowSemana {
  display: flex;
}

/* Tabelas e containers mobile/pc */

.tableResponsiveFlex {
  display: flex;
  flex-direction: column;
}

.headerTableResponsiveFlex {
  border-bottom: 1px solid #ccc;
  font-weight: 700;
  background-color: #f4f5f7;
  justify-content: center;
  align-items: center;
}

.rowTableResponsiveFlex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.rowTableResponsiveFlex:hover {
  background-color: #f4f5f7;
}

.rowHeaderTableResponsiveFlex {
  display: flex;
}

.cellHeaderTableResponsiveFlex {
  padding: 10px;
  margin-top: 1px;
  margin-bottom: 1px;
  flex: 1;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
}

.cellTableResponsiveFlex {
  padding: 12px;
  flex: 1;
  cursor: pointer;
}

.containerItensPc {
  display: block;
}

.containerItensCelular {
  display: none;
}

@media (max-width: 575.98px) {
  .containerItensPc {
    display: none;
  }

  .containerItensCelular {
    display: block;
  }
}

.no-ripple {
  --ripple-color: transparent !important;
  --background-activated: transparent !important;
  --background-activated-opacity: 0 !important;
  --background-focused: transparent !important;
  --background-focused-opacity: 0 !important;
  --background-hover: transparent !important;
}

/* Defina as animações de entrada e saída aqui */
.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms;
}

/* styles.css (ou o nome do seu arquivo CSS) */
.circle {
  width: 12px;
  height: 12px;
  background-color: #13aad7;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}

.line {
  width: 5px;
  height: 110%;
  background-color: #13aad7;
  position: absolute;
  left: 2%;
  transform: translateX(-50%);
  top: 20%;
}

.ec-event-title {
  font-size: 12px !important;
}

.ec-event-time {
  font-size: 12px !important;
}

.ec-title {
  text-transform: capitalize !important;
  font-size: 14px !important;
  margin-left: 5px;
}

.ec-days {
  font-size: 12px !important;
}

.ec-day-head {
  font-size: 15px !important;
}

.ec-time {
  font-size: 13px !important;
}
